import React, { useContext } from 'react';
import ItemsContext from '../context/items-context'

const Item = ({ item }) => {
  const { itemsDispatch } = useContext(ItemsContext);

  const handleRemove = (name) => {
    itemsDispatch({ type: 'REMOVE_ITEM', name });
  };

  return (
    <>
      <h6 dangerouslySetInnerHTML={{ __html: item.name }} />
      {/* <div className="is-size-6 is-italic mt-2" dangerouslySetInnerHTML={{ __html: item.info }} /> */}
      <button
        value={item.name}
        className="button link mt-1 mb-5"
        onClick={() => handleRemove(item.name)}
      >
        UNSELECT
      </button>
    </>
  );
};

export { Item as default };
