import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import ItemList from '../../components/ClassLists/ItemList'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      selections: ''
    }
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {
    this.setSelections();
    console.log('mount: ' + this.state.selections)
  }

  setSelections = () => {
    const storageItems = localStorage.getItem("items")
    this.setState({
      selections: storageItems
    });
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    console.log('submit: ' + this.state.selections)
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .then(() => localStorage.clear())
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            minHeight: '450px',
            height: '50vh',
            backgroundImage: `url(../img/contact-hero.jpg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
        </div>
        <div id="classAnchor"></div>
        <div className="has-background-white-ter section py-6 is-hidden-tablet">
          <div>
            <ItemList />
          </div>
        </div>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-two-thirds-desktop">
                <div className="section" style={{
                  maxWidth: '700px',
                  margin: '0 0 0 auto'
                }}>
                  <h1>Contact</h1>
                  <p>Interested in working with Babes Helpin' Babes? Fill out the form below so we can connect with you.</p>
                  <form
                    name="contact"
                    method="post"
                    action="/contact/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <input type="hidden" name="selections" value={this.state.selections} />
                    <span className="label">
                      Name*
                      </span>
                    <div className="field is-grouped">
                      <div className="control is-expanded">
                        <input
                          className="input"
                          type={'text'}
                          name={'firstname'}
                          onChange={this.handleChange}
                          id={'firstname'}
                          required={true}
                        />
                        <label className="label has-text-weight-normal smaller" htmlFor={'firstname'}>
                          First
                        </label>
                      </div>
                      <div className="control is-expanded">
                        <input
                          className="input"
                          type={'text'}
                          name={'lastname'}
                          onChange={this.handleChange}
                          id={'lastname'}
                          required={true}
                        />
                        <label className="label has-text-weight-normal smaller" htmlFor={'lastname'}>
                          Last
                        </label>
                      </div>
                    </div>
                    <div className="field is-grouped">
                      <div className="control is-expanded">
                        <label className="label" htmlFor={'company'}>
                          Company*
                        </label>
                        <input
                          className="input"
                          type={'text'}
                          name={'company'}
                          onChange={this.handleChange}
                          id={'company'}
                          required={true}
                        />
                      </div>
                      <div className="control is-expanded">
                        <label className="label" htmlFor={'title'}>
                          Title*
                        </label>
                        <input
                          className="input"
                          type={'text'}
                          name={'title'}
                          onChange={this.handleChange}
                          id={'title'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field mt-4">
                      <label className="label" htmlFor={'email'}>
                        Email Address*
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'email'}
                          name={'email'}
                          onChange={this.handleChange}
                          id={'email'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field mt-4">
                      <label className="label" htmlFor={'message'}>
                        How Can We Help?*<br />
                        <span className="smaller">Please do not include confidential or sensitive information in your message.</span>
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name={'message'}
                          onChange={this.handleChange}
                          id={'message'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <button className="btn is-link" type="submit">
                        SUBMIT
                      </button>
                    </div>
                  </form>

                </div>
              </div>
              <div className="column is-one-third-desktop">
                <div className="section">
                  <h6>LOCATION</h6>
                  <p className="has-text-weight-bold smaller">Kansas City, MO</p>
                  <h6 className="mt-4">OFFICE HOURS</h6>
                  <p className="has-text-weight-bold smaller">Monday - Friday<br />
                  8AM - 5PM</p>
                  <h6>CONTACT</h6>
                  <p className="has-text-weight-bold smaller">contact@babeshelpinbabes.com<br />
                  (913) 957-6739</p>
                </div>
                <div className="has-background-white-ter section py-5 is-hidden-mobile">
                  <div>
                    <ItemList />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
