import React, { useContext } from 'react'
import { Link } from 'gatsby'
import ItemsContext from '../../components/context/items-context'
import Item from './Item'
import { v4 as uuidv4 } from 'uuid'

const ItemList = () => {
  const { items } = useContext(ItemsContext);
  return (
    <>
      {items.length <= 0 ? null : <h4 className="is-size-2">You've made some great selections.</h4>}
      {
        items.length <= 0 ?
          <p className="is-size-6 mb-0">You haven’t selected any of our offerings. To give us a better idea of what you’re looking for, visit <Link to="../get-started/" className="is-underline has-text-weight-bold">Get Started</Link> and select BHB offerings your company could benefit from.</p> :
          <p className="is-size-6 pb-5" style={{ lineHeight: '1.2', borderBottom: "1px solid #ccc" }}>These are not purchases. Your selections simply let our team know what programming you're interested in when submitting your contact form.</p>
      }
      <ul>
        {items.map((item) => (
          <li key={uuidv4()}>
            <Item item={item} />
          </li>
        ))}
      </ul>
    </>
  );
};

export { ItemList as default };
